class MobileApp {
    constructor() {
        this.init();
    }

    /**
     * Определение мобильного устройства.
     * @returns {boolean}
     */
    isMobile() {
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            return true;
        }
        return false;
    }

    /**
     * Определение мобильной системы.
     * Функция возвращает одно из значений 'iOS', 'Android', 'Windows Phone', или 'unknown'.
     *
     * @returns {String}
     */
    getMobileOperatingSystem() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone должен быть первым, т.к. его UA так же содержит "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }

        return "unknown";
    }

    // Получение значения из sessionStorage
    getSessionStorageValue() {
        if (typeof window !== 'undefined') {
            return sessionStorage.getItem('hideMobileApp');
        }
    }

    // Запись значения в sessionStorage
    setSessionStorageValue() {
        if (typeof window !== 'undefined') {
            return sessionStorage.setItem('hideMobileApp', "true");
        }
    }

    init() {
        const $body = $("body"),
        $mobileApp = $('.mobile-app'),
        $mobileAppBtn = $('.mobile-app__btn'),
        self = this;

        // Если мобильное устройство и нет записи в sessionStorage
        if (this.isMobile() && !this.getSessionStorageValue() && window.location.pathname !== '/authorize') {
            $mobileApp.addClass('mobile-app--show');
        }

        // Меняем ссылку для Android устройств
        if (this.getMobileOperatingSystem() === 'Android') {
            $mobileAppBtn.prop("href", "https://play.google.com/store/apps/details?id=ru.dobro")
        }

        $body.on("click", ".mobile-app-close", function (e) {
            $mobileApp.toggleClass('mobile-app--show');
            self.setSessionStorageValue();

            e.preventDefault();
        })
    }
}

export default MobileApp;