import CookieInterface from "../../../abstracts/js/CookieInterface/CookieInterface";

// Нотификация для cookies
class CookiesNotification {
    constructor() {
        this.showModal = false;
        this.$cookiesNotification = $('.cookies-consent-modal');
        this.$volunteerId = this.$cookiesNotification.attr('data-volunteer-id'); // 1/0
        this.init();
    }

    isNotificationShow() {
        // Показывать на всех страницах
        if (!window.location.pathname.includes('/authorize')) {
            if (
                !CookieInterface.readCookie('cookie_consent')
            ) {
                this.showModal = true;
            }
        }
    }

    init() {

        this.isNotificationShow();

        const consentCookie = CookieInterface.readCookie('cookie_consent');

        if (this.$cookiesNotification && !consentCookie && this.showModal) {
            const self = this;
            self.$cookiesNotification.addClass('cookies-consent-modal--show');

            const expires = self.$volunteerId ? 365 : null;

            $(document).on("click", ".cookies-consent-modal__accept", function () {
                // Скрываем модалку
                self.$cookiesNotification.removeClass('cookies-consent-modal--show');
                CookieInterface.createCookie('cookie_consent', 1, expires)

                if (self.$volunteerId) {
                    let token = CookieInterface.readCookie('switch_dobro_access_token') ?? CookieInterface.readCookie('dobro_access_token');
                    $.ajax({
                        url: `/api/v2/volunteers/${self.$volunteerId}/cookie_consent`,
                        type: 'PUT',
                        data: JSON.stringify({value: true}),
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        },
                        error: function (error) {
                            console.error(error)
                        }
                    });
                }
            });
        }
    }
}

export default CookiesNotification;
