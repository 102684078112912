import './modal-slider.scss';
import 'slick-carousel/slick/slick';
import 'slick-carousel/slick/slick.css';
import CookieInterface from "../../../abstracts/js/CookieInterface/CookieInterface";

export default class ModalSlider {
    constructor() {
        this.init();
    }


    init() {
        const $modalSlider = $('.js-modal-slider'),
            $modalSliderSlides = $('.js-modal-slider-slides');

        if ($modalSlider.length === 0) {
            return;
        }

        // noinspection JSIncompatibleTypesComparison
        if ((CookieInterface.readCookie('disableModalSlider') === '1')){
            return;
        }

        // Инициализируем слайдер
        $modalSlider.on('shown.bs.modal', function () {
            $modalSliderSlides.slick({
                infinite: false,
                autoplay: false,
                adaptiveHeight: false,
                prevArrow: '<button type="button" class="slick-prev modal-slider__btn modal-slider__btn--prev"></button>',
                nextArrow: '<button type="button" class="slick-next modal-slider__btn modal-slider__btn--next"></button>'
            });

            CookieInterface.createCookie('disableModalSlider', '1', 30);
        });

        // Отображаем модалку с таймаутом
        setTimeout(() => {
            $modalSlider.modal('show');
        }, 500);
    }
}