import CookieInterface from "./abstracts/js/CookieInterface/CookieInterface";
import 'bootstrap';
import 'jquery-ui-dist/jquery-ui';
import toastr from 'toastr';
import tippy from 'tippy.js';
import Chat from "./pages/_snippets/chat";
import Notifications from "./pages/_snippets/notifications";
import Actions from './blocks/actions/actions';
import './abstracts/js/Fingerprint/Fingerprint';
import ModalSlider from "./pages/_snippets/modal-slider/modal-slider";
import Callbacks from "./abstracts/js/Summernote/MaxLengthCallbacks";
import MobileApp from './pages/_snippets/mobile-app/mobile-app';
// import WeAreTogetherModal from './pages/_snippets/we-are-together-modal/we-are-together-modal';
// import DobroInfoModal from './pages/_snippets/dobro-info-modal/dobro-info-modal';
import CookiesNotification from './pages/_snippets/cookies-notification/cookies-notification'

import 'bootstrap-select';
import 'bootstrap-select/dist/css/bootstrap-select.min.css';

import './blocks/form/validate.scss';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import './blocks/help/help.scss';
import 'simplebar/dist/simplebar.css';
import 'jquery-ui-dist/jquery-ui.css';
import './blocks/form/__input_select/__input_select.scss';
import './pages/_snippets/mobile-app/mobile-app.scss';
// import './pages/_snippets/we-are-together-modal/we-are-together-modal.scss';
// import './pages/_snippets/dobro-info-modal/dobro-info-modal.scss';
import './pages/_snippets/cookies-notification/cookies-notification.scss'

import '@fancyapps/fancybox/dist/jquery.fancybox.css';

const $ = require('jquery');
global.$ = global.jQuery = $;

require('./abstracts/js/Summernote/Summernote');
var sbjs = require('sourcebuster');

$(document).ready(function () {
    // запускаем сбор данных об источнике трафика
    sbjs.init({
        domain: {
            host: 'dobro.ru',
            isolate: true
        }
    });

    // Запускаем чат и уведомления
    new Chat();
    new Notifications();

    //Добавляем обработку спец. ссылок
    new Actions();

    // Подключаем модалку с слайдером
    new ModalSlider();

    // Подключаем fancybox
    require('@fancyapps/fancybox');

    // Подключаем плашку моб. приложения
    new MobileApp();

    // // Подключаем попап премии МыВместе
    // new WeAreTogetherModal();

    // // Подключение попапа Другого Дела
    // new DobroInfoModal();

    // Подключение уведомления о cookies
    new CookiesNotification();

    // QuillJS
    $('.js-wysiwyg-text-editor').summernoteEditor();

    $('[data-toggle="popover"]').popover();

    if (typeof $("section").data("menu-color") !== "undefined") {
        $(".header").addClass("black");
    }

    if (typeof $(".main-container").data("menu-color") !== "undefined") {
        $(".header").addClass("black");
    }

    $('.beOrganizer').on('click', function (event) {
        event.stopPropagation();
        $('#be-organizer').modal('show');
    });

    const $volunteerBookModal = $('#volunteerBookModal');

    function openVolunteerBookModal(event) {
        event.preventDefault();
        $volunteerBookModal.show();
    }

    function closeVolunteerBookModal(event) {
        if (event.target == $volunteerBookModal[0]) {
            $volunteerBookModal.hide();
        }
    }

    $('.js-openVolunteerBookModal').on('click', openVolunteerBookModal);
    $(window).on('click', closeVolunteerBookModal);

    const ymData = [
        {
            text: 'Добавить организацию',
            goal: 'add_organization_profile'
        },
        {
            text: 'Стать наставником',
            goal: 'become_mentor'
        },
        {
            text: 'Смотреть книжку волонтера',
            goal: 'watch_volunteers_book'
        },
        {
            text: 'Получить книжку волонтёра',
            goal: 'get_volunteers_book'
        },
    ];

    $('.user-menu-container a').each(function () {
        const $link = $(this);
        const linkText = $link.text().trim();

        ymData.forEach(({text, goal}) => {
            if (linkText === text) {
                $link.on('click', function () {
                    if (typeof ym !== 'undefined') {
                        ym(44986651, 'reachGoal', goal);
                    }
                });
            }
        });
    });


    var beOrganizeButtons = document.querySelectorAll('#beOrganize');
    beOrganizeButtons.forEach(function (button) {
        button.addEventListener('click', function (event) {
            console.log(event);
            event.stopPropagation();
            $('#be-organizer').modal('show');
        });
    });


    const modal = document.getElementById("myModal");

    if (modal) {
        const btns = document.querySelectorAll(".js-openModal");

        function openModal(event) {
            event.preventDefault();
            modal.style.display = "block";
        }

        function closeModal(event) {
            if (event.target == modal) {
                modal.style.display = "none";
            }
        }

        btns.forEach(btn => {
            btn.addEventListener('click', openModal)
        });
        window.addEventListener('click', closeModal);
        modal.style.display = "none";
    }
    /// показать скрыть
    const toggleLinks = (links, startIndex, isHidden, buttonSelector) => {
        links.forEach((link, index) => {
            if (index >= startIndex) {
                if (link.textContent.includes('Стать наставником') || link.textContent.includes('Стать организатором') || link.textContent.includes('Добавить организацию')) {
                    link.style.display = 'flex';
                } else {
                    link.style.display = isHidden ? 'none' : 'flex';
                }
            }
        });
        const buttons = document.querySelectorAll(buttonSelector);
        buttons.forEach(buttonElement => {
            buttonElement.style.display = links.length <= startIndex ? 'none' : 'block';
            buttonElement.textContent = isHidden ? 'Показать ещё' : 'Скрыть';
            buttonElement.classList.toggle('hidden', isHidden);
        });
    };

    const containerSettings = [
        {container: '.js-organizer', button: '.organizer', hiddenState: 'isHiddenorganizer'},
        {container: '.js-mentor', button: '.mentor', hiddenState: 'isHiddenmentor'}
    ];

    containerSettings.forEach(({container, button}) => {
        const containerElements = document.querySelectorAll(container);
        containerElements.forEach(containerElement => {

            const links = containerElement.querySelectorAll('a');
            if (links.length === 0) {
                containerElement.style.display = 'none';
            }

            const startIndex = links.length > 3 ? 2 : 3;
            let isHidden = true;

            toggleLinks(links, startIndex, isHidden, button);

            document.querySelectorAll(button).forEach(buttonElement => {
                buttonElement.addEventListener('click', (event) => {
                    event.stopPropagation();
                    isHidden = !isHidden;
                    toggleLinks(links, startIndex, isHidden, button);
                });
            });
        });
    });

    /**
     * Обработка формы в футере
     */
    const subscribeTippyInstance = tippy($('.js-footer-subscribe')[0], {
        theme: 'light',
        content: '<p class="font-size-18 pl-2 pr-2 pt-1 pb-1">Спасибо за подписку!</p>',
        trigger: 'manual',
        arrow: false,
        allowHTML: true,
        placement: $(document).width() > 750 ? 'left' : 'top',
    });

    $(".js-footer-subscribe").on("submit", function (e) {
        const $form = $(this);
        const $input = $form.find("input[type=email]");

        $.post($form.attr("action"), $form.serialize(), function () {
            $input.val(" ");
            $input.trigger("focusout");

            subscribeTippyInstance.show();
            setTimeout(() => {
                subscribeTippyInstance.hide();
                subscribeTippyInstance.destroy();
            }, 3000);
        }).fail(function (error) {
            if (error.responseJSON !== undefined) {
                toastr.warning(error.responseJSON.message);
            } else {
                toastr.error('Что-то пошло не так')
            }
        });

        e.preventDefault();
    });

    toastr.options = {
        "closeButton": true,
        "debug": false,
        "progressBar": true,
        "preventDuplicates": false,
        "positionClass": "toast-top-right",
        "onclick": null,
        "showDuration": "400",
        "hideDuration": "1000",
        "timeOut": "7000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "slideDown",
        "hideMethod": "fadeOut"
    };
    global.toastr = toastr;

    global.tippy = tippy;
    (window.initTippyHelpersByDataAttr = () => {
        tippy('[data-tippy-content]', {
            theme: 'light',
            allowHTML: true,
            interactive: true,
            appendTo: document.body,
        });
    })();

    (window.initTippyHelpersByHelpElem = () => {
        const $helpers = $(".help-text");
        $helpers.each((i, element) => {
            const $element = $(element);

            let $input = $element.prev();
            while ($input.hasClass('skip-help')) {
                $input = $input.prev();
            }

            let inputClass = '',
                helperClass = '',
                isDisabled = typeof $input.attr("disabled") !== "undefined";

            if ($element.html().length === 0 || $input.length === 0) return;

            tippy(isDisabled ? $input.closest(".form__group")[0] : $element[0], {
                theme: 'light',
                content: $element.html(),
                allowHTML: true,
                interactive: true,
                appendTo: document.body,
            });

            if (isDisabled) return;

            if ($input.is("input")) {
                if ($input.attr("type") === "text" || $input.attr("type") === "number") {
                    inputClass = 'input-with-helper';
                    if ($input.hasClass('form__input_date')) {
                        helperClass = 'helper-for-date'
                    } else {
                        helperClass = 'helper-for-input';
                    }
                } else if ($input.attr("type") === "checkbox") {
                    helperClass = 'helper-for-checkbox';
                } else if ($input.attr("type") === "hidden") {
                    helperClass = 'helper-for-file-dropzone';
                }
            } else if ($input.is('select')) {
                inputClass = 'select-with-helper';
                helperClass = 'helper-for-select';
            } else if ($input.hasClass('form__textarea_label')) {
                helperClass = 'helper-for-textarea';
            } else {
                helperClass = 'helper-for-span';
            }

            $input.addClass(inputClass);
            $element.addClass(helperClass);
        });
    })();

    /**
     * Обработка жалоб
     */
    (() => {
        if ($('.js-send-complaint').length > 0) {
            $(".js-send-complaint button").on("click", function () {
                const complainText = $(this).text(),
                    $link = $(this).closest(".btn-group").find(".js-complain-link");

                $.post($link.data("url"), {"message": complainText}, function (response) {
                    toastr.success(response.message);
                }).fail((xhr) => {
                    const error = JSON.parse(xhr['responseText'])['message'];
                    toastr.error(error);
                });
            });
        }
    })();

    (() => {
        window.initSelect = () => {
            const $select = $(".form__input_select");
            const $formGroupWidth = $select.closest(".form__group").width();

            // noinspection JSUnusedGlobalSymbols,JSUnresolvedFunction
            $select.selectpicker({
                width: '100%',
                countSelectedText: function () {
                    return 'Вся Россия';
                },
                noneResultsText: "Ничего не найдено!",
                selectOnTab: true
            })
                .on("loaded.bs.select, show.bs.select", () => {
                    const $formGroup = $select.closest(".form__group");

                    if ($formGroup.hasClass("full-width-select")) return;

                    $select.closest(".form__group").find("div.dropdown-menu").width($formGroupWidth - 3);
                });
        };

        window.initSelect();
    })();

    const $body = $('body');

    (() => {
        $('.js-textarea-with-counter').each(Callbacks.onInit);
        $body.on('keyup', '.js-textarea-with-counter', Callbacks.onKeyup);
        $body.on('keydown', '.js-textarea-with-counter', Callbacks.onKeydown);
        $body.on('paste', '.js-textarea-with-counter', Callbacks.onPaste);
    })();

    $body.on('shown.bs.modal', '#be-physical', () => {
        $('#be-organizer').modal('hide');
    });

    const toastCookies = CookieInterface.readCookie('toastr_flashes');

    function showFlashes(flashes, type) {
        if (flashes.hasOwnProperty(type) && Array.isArray(flashes[type])) {
            flashes[type].map((flash) => {
                if (type === 'success') {
                    toastr.success(flash);
                }
                if (type === 'error') {
                    toastr.error(flash);
                }
                if (type === 'info') {
                    toastr.info(flash);
                }
                if (type === 'warning') {
                    toastr.warning(flash);
                }
            })
        }
    }

    if (toastCookies) {
        try {
            const flashes = $.parseJSON(decodeURIComponent(toastCookies));
            showFlashes(flashes, 'success');
            showFlashes(flashes, 'error');
            showFlashes(flashes, 'info');
            showFlashes(flashes, 'warning');
            document.cookie = `toastr_flashes=[];expires=-1;path=/`;
        } catch (ex) {
        }
    }

    $('.js-oauth-button').on('click', function (e) {
        $body.find('.js-oauth-button').addClass('disabled');
        $(this).find('.js-oauth-spinner').removeClass('d-none');
    });
})
