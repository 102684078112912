class Notifications {
    constructor() {
        this.$notificationsIcon = $('.js-notifications-icon');

        // Если нотификации включены и пользователь зарегистрирован в чате
        if (this.$notificationsIcon.length) {
            // Активируем обработчик открытия нотификаций
            this.$notificationsIcon.one('click', $.proxy(this.recent, this));
        }
    }

    recent() {
        $.get(this.$notificationsIcon.data('notifications-recent-url'), $.proxy(this.recentResponseHandler, this))
    }

    recentResponseHandler(response) {
        this.$notificationsIcon.next('.dropdown-menu').html(response);
        if (this.$notificationsIcon.hasClass('js-notifications-icon--navbar')) {
            // чтобы пересчиталась высота блока, т.к. изначально (пока не подгрузились данные) рассчитывается
            // неверно и в моб.версии при отображении у нижнего навбара блок съезжает вниз)
            this.$notificationsIcon.dropdown('update');
        }
    }
}

export default Notifications;